.suggestMechanism:hover, .seeReaction:hover, .suggestNote:hover, .suggestComm:hover {
    text-decoration: underline;
}

.suggestDiv, .suggestDivNote {
    border: 2px solid rgb(23, 55, 151);
    z-index: 3;
    right: 0; 
    left:0;
    margin-left: auto; 
    margin-right: auto; 
    margin-inline: auto;
    display:"none";
    width:max-content/* Need a specific value to work */;
    position: absolute;
    padding:5px;
    background-color: rgb(23, 55, 151);
}

.suggestInput, .suggestInputNote, .suggestInputComm {
    border: 1px solid lightgrey;
    margin-right: 5px;
    font-size: 12px;
}

.confirmButton, .confirmButtonNote, .confirmButtonComm {
    background-color: rgb(245,245,245);
    color:black;
    font-size: 12px;
}


.infoCircle:hover ~ .displayComment, .displayComment:hover {
    display:block
}

.displayComment {
    position:absolute;
    z-index: 0;
    background-color: rgb(23, 55, 151);
    color: white;
    padding:8px;
    font-size: small;
    display:none;
    z-index: 5;
    left: 0;
    right: 0;
    margin-inline: auto
}

.displayNote {
    display:none;
    background-color:rgb(23,55,151);
    position:absolute;
    color:white;
    font-size: 12px;
    min-width:max-content;
    padding:5px;
    right:0;
    left:0;
    margin-inline: auto;
    width:fit-content;
    top:1.75rem;
    z-index: 10;

}

#reactionSuggestionRow {
    position: relative;
    max-width: fit-content;
    margin-left: auto;
    margin-right: auto
}

.displayComment button:hover {
    text-decoration: underline;
}

#publicationFourColumns {
    margin:0 auto;
    column-count:4
}

#mySuggestions {
    margin-right: 150px;
    margin-left: 150px;
    padding-top: 120px;
    padding-bottom: 100px
}





@media (max-width: 900px) {
    #publicationFourColumns {
        margin:0 auto;
        column-count:4;
        font-size:8px
    }

    #mySuggestions {
        margin-right: 50px;
        margin-left: 50px;
        padding-top: 60px;
        padding-bottom: 50px
    }

    #mySuggestions h2 {
        font-size:15px
    }
}