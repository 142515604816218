#tableMech {
    margin-top:20px;
    margin-bottom:20px;
    width:75%
}

#headerCol {
    padding-top:10px;
    padding-bottom:10px;
    border:1px solid lightgrey;
}

#dataColMech {
    background-color: rgb(245, 245, 245);
    border:1px solid lightgrey;
    text-align: center;
    word-wrap: break-word;
    max-width: 20ch;
}

.DDPubMech {
    background-color: rgb(245, 245, 245);
    margin-top: 10px;
    border:1px solid lightgrey;
    padding:15px;
    position: absolute;
    width:100%;
    margin-right:150px;
    overflow-x: scroll;
}

.publicationList {
    color:black;
    text-decoration: none;
    word-wrap: break-word;
    max-width: 33ch;
}

#publicationUl {
    margin:0;
    padding:0px;
    list-style: none;
}

.iconButtonReact, .iconButtonProd  {
    border:none;
    background-color: rgb(245,245,245);
}

.copyNoteReact {
    position:absolute;
    top:-2.5rem;
    left:5.5rem;
    background-color: rgb(23, 55, 151);
    color: white;
    padding:8px;
    font-size: small;
    opacity: 0;
    transition: all 250ms ease-in-out;
}

.iconButtonReact:hover ~ .copyNoteReact {
    opacity: 1;
}

.copyNoteReact::before {
    content:"";
    position:absolute;
    bottom:-0.3rem;
    right:3.5rem;
    width:10px;
    height:10px;
    background-color: rgb(23, 55, 151);
    transform:rotate(45deg);
    border-right:3px solid rgb(23, 55, 151);
    border-bottom:3px solid rgb(23, 55, 151);
}

.copiedNoteReact {
    position:absolute;
    top:-2.5rem;
    left:6.95rem;
    background-color: rgb(23, 55, 151);
    color: white;
    padding:8px;
    font-size: small;
    display:none;
    opacity: 1;
    transition: all 250ms ease-in-out;
}

.iconButtonReact:hover ~ .copiedNoteReact {
    opacity: 1;
}

.copiedNoteReact::before {
    content:"";
    position:absolute;
    bottom:-0.3rem;
    right:2rem;
    width:10px;
    height:10px;
    background-color: rgb(23, 55, 151);
    transform:rotate(45deg);
    border-right:3px solid rgb(23, 55, 151);
    border-bottom:3px solid rgb(23, 55, 151);
}

.copyNoteProd {
    position:absolute;
    top:-2.5rem;
    left:4.8rem;
    background-color: rgb(23, 55, 151);
    color: white;
    padding:8px;
    font-size: small;
    opacity:0;
    transition: all 250ms ease-in-out;
}

.iconButtonProd:hover ~ .copyNoteProd {
    opacity:1
}

.copyNoteProd::before {
    content:"";
    position:absolute;
    bottom:-0.3rem;
    right:3.5rem;
    width:10px;
    height:10px;
    background-color: rgb(23, 55, 151);
    transform:rotate(45deg);
    border-right:3px solid rgb(23, 55, 151);
    border-bottom:3px solid rgb(23, 55, 151);
}

.copiedNoteProd {
    position:absolute;
    top:-2.5rem;
    left:6.25rem;
    background-color: rgb(23, 55, 151);
    color: white;
    padding:8px;
    font-size: small;
    display:none;
    opacity:0;
    transition: all 250ms ease-in-out;
}

.iconButtonProd:hover ~ .copiedNoteProd {
    opacity:1
}

.copiedNoteProd::before {
    content:"";
    position:absolute;
    bottom:-0.3rem;
    right:2rem;
    width:10px;
    height:10px;
    background-color: rgb(23, 55, 151);
    transform:rotate(45deg);
    border-right:3px solid rgb(23, 55, 151);
    border-bottom:3px solid rgb(23, 55, 151);
}


