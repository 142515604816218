#EnzymesButton, #ReactantsButton, #ProductsButton {
    border:none;
    background-color: white;
}


#name, #type, #class, #uniprotId, #genbankId, #reactantName,#smiles,#chebi,#productName,#productSmiles,#productChebi {
    background-color: white;
    display:none;
    border:none
}

#EnzymesButton:hover, #ReactantsButton:hover, #ProductsButton:hover {
    background-color:rgb(23, 55, 151);
    color:white
}

#confirmButton,#confirmButtonAnalogy {
    background-color:rgb(23, 55, 151);
    color:white;
    padding:5px
}

#SearchTitle {
    height:100vh;
    width:100vw;
    background-image:url("../../background.jpg");
    background-repeat:no-repeat;
    background-size:cover;
}

#NameInput, #TypeInput, #KingdomInput #ClassInput, #GenbankInput, #UniprotInput, #ReactantSMILESInput, #ReactantNameInput, #ReactantChebiInput, #ProductSMILESInput,
#ProductNameInput, #ProductChebiInput, #IntermediateSMILESInput {
    margin-top:10px;
    padding:5px;
    width:100%;
    margin-bottom: 10px;
}


#inputs, #analogyinputs {
    margin-top: 50px
}

#confirmButton {
    background-color:rgb(23, 55, 151);
    color:white;
    height:35px;
}

#advancedbutton {
    background-color: rgb(23, 55, 151);
    color: white;
    padding-top:5px;
    padding-bottom:5px;
    margin-top: 10px;
    border: 1px solid white
}



#DiTerpInput, #MonoTerpInput, #TriIntTerpInput, #TetraIntTerpInput, #DiIntTerpInput, #SesqTerpInput, #TriTerpInput, #TetraTerpInput,
 #PrenylTerpInput, #HemiTerpInput, #SesterTerpInput, #SesquarTerpInput, #ClassI, #ClassII,#AnimaliaMammalInput,#AnimaliaCoralInput,
 #AnimaliaMarineSpongeInput,#AnimaliaMammalHumanInput,#AnimaliaInsectInput,#FungiInput,#BacteriaInput,#CyanobacteriaInput,
 #PlantaeRedAlgaeInput,#VirusesInput,#ArchaeaInput,#AmoebozoaInput,#PlantaeInput, #AnimaliaBirdInput {
    margin-right: 10px
  }

#SearchSuccess {
    padding:20px;
    background-color: rgb(245, 245, 245);
    border:1px solid lightgrey;
    width:100%;
    margin-bottom:25px;
    display:none;
    justify-content: space-between;
}

#DoubleDown {
    border:none;
    background-color: rgb(245, 245, 245);
}

#arrow {
    color: rgb(150, 150, 150);
}

#SearchSuccess ul {
        margin:0;
        padding:0;
        list-style: none;
        display:flex;
        gap:0rem;
        align-items:center;
        justify-content: space-between;
}

#SearchInstructions {
    margin-left:150px;
    margin-top: 5px;
    color:white;
    padding:5px;
    background-color: rgb(245, 245, 245,0.25);
    width:100%;
    transition: left 1s cubic-bezier(.25,0.1,.25,1);
}

#EnzymeInputs, #ReactantInputs,#ProductInputs {
    display:flex;
    justify-content: space-between;
    list-style: none;
    width:100%;
    margin-top: 10px;
}

#EnzymeInputs:hover, #ReactantInputs:hover, #ProductInputs:hover {
    background-color: rgb(245,245,245);
}

#filters ul {
    margin:0;
    padding:0;
}

.reactionContent {
    scroll-margin-top: 170px;
}

#scrollDownNote {
    position:absolute;
    top:-2.25rem;
    right:-1.25rem;
    background-color: rgb(23, 55, 151);
    color: white;
    padding:8px;
    font-size: small;
    opacity: 0;
    transition: all 250ms ease-in-out;
}

#DoubleDown:hover ~ #scrollDownNote {
    opacity:1
}

#clearEnzymeInputs, #clearSubstrateInputs, #clearProductInputs, #clearMechanismInputs {
    border:none;
    border-bottom: 2px solid lightgrey;
    background-color: white
}

#tableSection {
    margin-right: 150px;
    margin-left: 150px;
    padding-top: 100px
}

#filtersMinimized {
    position: fixed;
    z-index: 4;
    border: 1px solid lightgrey;
    margin-top: 100px;
    margin-left: 150px;
    padding: 0px;
    background-color:rgb(245,245,245);
}

#filtersMinimizedUl {
    position: relative;
    list-style: none;
    width: 100px;
    padding: 0px;
    display: flex;
    justify-content: space-between;
    margin:0 auto;
}

#sectionFilters {
    position: fixed;
    z-index: 100;
    background-color: rgb(255,255,255);
    box-shadow:
      0 6px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
    left: 0px;
    right:0px;
    top:0px;
    padding-bottom:10px;
    height:400px;
    overflow-y:scroll
}

#filtersMargins {
    margin-left:150px;
    margin-right:150px
}

#SimilarityEvidence, #CalculationEvidence, #OriginalEvidence, #ExperimentEvidence, #TrivialEvidence {
    margin-right: 10px
}

#EnzymeInputs, #MoleculeInputs, #EvidenceInput, #ReactantInputs, #ProductInputs, #AnalogyInputs {
    border-bottom: 2px solid lightgrey;
    padding:10px
}

#EvidenceInput {
    display:flex;
    justify-content: space-between;
    list-style: none;
    width:100%;
    margin-top: 10px;
}

#EvidenceInput:hover {
    background-color: rgb(245,245,245);
}

#Database {
    list-style:none;
    padding-top:20px;
    margin-left:110px
}

#reaction, #mechanism, #enzyme, #reactant, #product, #searchMechanism, #searchReaction {
    background-color: rgb(245, 245, 245);
    border-radius:10px;
    border:1px solid lightgrey;
    display:none;
    width:91%;
}

#SearchButton, #EntryButton, #EffectButton {
    width:95%;
    border:none;
    padding-top:40px;
    padding-bottom:40px;
    padding-right:0px;
    border-radius:20px;
    background-color: rgb(245, 245, 245);
    text-align: center;
    font-size:30px;
    border:1px solid lightgrey
}

#SearchButton:hover, #EntryButton:hover, #reaction:hover, #mechanism:hover, #enzyme:hover, #reactant:hover, #product:hover,
#searchReaction:hover, #searchMechanism:hover{
    background-color:rgb(23, 55, 151);
    color:white
}

#overviewBackground {
    margin-left: 150px;
    margin-right: 150px;
    margin-top:50px;
    border-bottom: 3px solid lightgrey;
    padding-bottom:25px
}


#overviewButton1, #overviewButton2, #overviewButton3 {
    break-after: column;
    background-color: rgb(245, 245, 245);
    border:solid 1px lightgrey;
    padding-top:75px;
    padding-bottom:5px;
    width:300px;
    font-size:25px;
}

#rowDiv li {
    list-style: none;
    margin-bottom: 2px;
}

#reactionButton1, #mechanismButton1,#reactionButton2, #mechanismButton2,#reactionButton3, #mechanismButton3 {
    background-color: rgb(245, 245, 245);
    border:solid 1px lightgrey;
    width:250px;
    padding:10px;
}

#reactionButton1:hover, 
#mechanismButton1:hover,
#reactionButton2:hover, 
#mechanismButton2:hover,
#reactionButton3:hover, 
#mechanismButton3:hover {
    background-color: gold;
}

#messageentry, #messagesuggestion {
    position:relative;
    top:15px;
    background-color: rgb(245,245,245);
    border:1px solid lightgrey;
    font-size: 1rem;
    padding:5px;
}

#dbIcon1,#dbIcon2,#dbIcon3, #suggIcon1,#suggIcon2,#suggIcon3, #searchIcon1,#searchIcon2,#searchIcon3 {
    filter:blur(0px);
    transition:all 250ms ease-in-out;
}

#overviewButton1:hover > #dbIcon1,
#overviewButton1:hover > #dbIcon2,
#overviewButton1:hover > #dbIcon3,
#overviewButton2:hover > #suggIcon1,
#overviewButton2:hover > #suggIcon2,
#overviewButton2:hover > #suggIcon3,
#overviewButton3:hover > #searchIcon1,
#overviewButton3:hover > #searchIcon2,
#overviewButton3:hover > #searchIcon3 {
    filter:blur(3px);
}

#overviewContainer {
    column-count: 3;
}

#submenu1, #submenu2, #submenu3{
    break-inside: avoid-column;
    margin-bottom:20px
}













  @media (max-width: 1250px) {
    #overviewContainer {
        column-count:1;
    }
  }

@media (max-width:900px) {
    #tableSection {
        margin-right: 50px;
        margin-left: 50px;
        padding-top: 50px
    }

    #filtersMinimized {
        position: fixed;
        z-index: 4;
        border: 1px solid lightgrey;
        margin-top: 100px;
        margin-left: 50px;
        padding: 0px;
        background-color:rgb(245,245,245);
        font-size: 10px;
    }

    #filtersMinimizedUl {
        position: relative;
        list-style: none;
        width: 75px;
        padding: 0px;
        display: flex;
        justify-content: space-between;
        margin:0 auto;
    }

    #scrollDownNote {
        font-size: 10px;
    }

    #filtersMargins {
        margin-left:50px;
        margin-right:50px
    }

    #sectionFilters {
        width:100vw
    }

    #submenu1, #submenu2, #submenu3{
        break-inside: avoid-column;
    }

    #overviewBackground {
        margin-left: 50px;
        margin-right: 50px;
        margin-top:25px;
        border-bottom: 3px solid lightgrey;
        padding-bottom:12px
    }

}






