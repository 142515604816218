.SignUpBackground {
    /*background-image:url("https://pluskal.group.uochb.cz/upload/files/41/01/4101bc0bf2b274e47c505573b9d959847bead1d0.jpg");*/
    background-image:url("../../background-png.png");
    background-repeat:no-repeat;
    background-size:cover;
    height:100vh;
    width:100vw
}

.SignUpAlignment {
    display:flex;
    justify-content:center;
    align-items:center
}

.SignUpBox {
    position:absolute;
    width:400px;
    background-color:white;
    padding:10px;
    top:15%;
}

.SignUpBox h4 {
    text-align:center;
    padding:10px
}

.SignUpFormDiv {
    display:flex;
    justify-content:center
}

.SignUpForm input {
    margin-bottom:10px;
    padding:5px;
    width:100%;
    outline:none
}

.SignUpButton {
    background-color:rgb(23, 55, 151);
    color:white;
    width:100%;
    height:35px
}

#UserLabel, #PasswdLabel, #ConfirmPasswdLabel, #EmailLabel, #FirstNameLabel, #LastNameLabel, #TitleLabel, #AffiliationLabel, #PhoneNumberLabel {
    display:flex
}

#uidnote, #passwdnote, #confirmnote, #emailnote {
    font-size:12px;
    background-color:rgb(23, 55, 151);
    color:white;
    padding:5px;
    width:100%
}

#info {
    margin-right:5px
}

#cross, #tick {
    margin-left:5px
}

#cross {
    color:red
}

#tick {
    color:green
}

#ButtonDiv {
    margin-top:24px
}

