#MechanismInputs {
    position: relative; 
    margin-right: 150px;
    margin-left: 150px  
}

#MechanismInputs textarea, #MechanismInputs input {
    background-color: rgb(245, 245, 245);
    margin-bottom:10px;
    border-radius:10px;
    padding:5px;
    width:100%;
}

#MechanismInputs h5 {
    border-bottom: 2px solid lightgrey;
    padding:10px;
    margin-bottom: 10px;
}

#addInput, #deleteInput {
    border: solid 1px rgb(150,150,150);
    background-color: rgb(245,245,245);
}

#addInput:hover, #deleteInput:hover {
    background-color: gold;
}

#buttonUl {
    list-style: none;
    display:inline-flex;
    gap:2rem
}

#submitButton {
    background-color:rgb(23, 55, 151);
    color:white;
    border-radius:10px;
    height:35px
}