.LoginBackground {
    /*background-image:url("https://pluskal.group.uochb.cz/upload/files/41/01/4101bc0bf2b274e47c505573b9d959847bead1d0.jpg");*/
    background-image:url("../../background-png.png");
    background-repeat:no-repeat;
    background-size:cover;
    height:100vh;
    width:100vw;
}

.LoginAlignment {
    display:flex;
    justify-content:center;
    align-items:center
}

.LoginBox {
    position:absolute;
    width:350px;
    background-color:rgb(255,255,255);
    padding:10px;
    top:25%;
}

.LoginBox h4 {
    text-align:center;
}

.LoginFormDiv {
    display:flex;
    justify-content:center
}

.LoginForm input {
    margin-bottom:10px;
    padding:5px;
    width:100%;
    outline:none
}

.LoginButton {
    background-color:rgb(23, 55, 151);
    color:white;
    width:100%;
    height:35px
}

#errorMessage {
    background-color:lightpink;
    color: red;
    border-radius:10px;
    padding:5px;
}