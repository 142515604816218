#Footer {
    margin-left:150px;
    margin-right:150px;
    border-bottom: solid lightgrey 1px;
    border-top: solid lightgrey 1px;
    padding-top:25px;
    padding-bottom:25px
  }

#FooterPic {
    padding-top:15px;
    padding-bottom:100px;
    background-image: url("../../public/icon_M.png");
    background-repeat: no-repeat;
    background-size:60px;
    background-position: center left;
    margin-left: 25px;
}

#BackgroundLower, #BackgroundUpper {
    background-color:rgb(239, 239, 239);
}

#BackgroundUpper {
    padding-top:50px
}

#BackgroundLower {
    margin-right:137px;
    margin-left:127px
}

#listValue {
    text-decoration: none;
    list-style: none;
    margin-left:-2rem;
    padding:0;
    color:lightgrey
}

#FooterLink {
    text-decoration: none;
    list-style: none;
    color:rgb(165, 165, 165)
}

#FooterLink:hover {
    text-decoration: underline;
}

#IOCB {
    align-self:center;
}

#FooterIOCB {
    align-self:center;
    color:rgb(121, 121, 121);
}

#IOCBLink {
    text-decoration: none;
    list-style: none;
    color:rgb(121, 121, 121)
}

#IOCBLink:hover {
    text-decoration: underline;
}




@media (max-width: 900px) {
    #Footer {
        margin-left:50px;
        margin-right:50px;
        border-bottom: solid lightgrey 1px;
        border-top: solid lightgrey 1px;
        padding-top:25px;
        padding-bottom:25px
      }

      #BackgroundLower {
        margin-right:50px;
        margin-left:0px
    }

    #FooterPic {
        padding-top:15px;
        padding-bottom:50px;
        /*background-image: url("../../uochb_logo.png");*/
        background-repeat: no-repeat;
        background-size:200px;
        background-position: center left;
        margin-left:25px
    }

    #listValue {
        text-decoration: none;
        list-style: none;
        color:lightgrey;
    }

    #FooterIOCB {
        align-self:center;
        color:rgb(121, 121, 121);
        padding-bottom:25px
    }

}