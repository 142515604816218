#fullFormMech {
    padding-bottom:25px;
}

.suggestDivMech {
    border-radius: 10px;
    border: 2px solid rgb(23, 55, 151);
    z-index: 3;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    width:max-content/* Need a specific value to work */;
}

#suggestMechanism:hover {
    text-decoration: underline;
}

#suggestMechanism {
    background-color: rgb(245,245,245);
    border:"none"
}