#OneEnzyme, #TwoEnzymes, #ThreeEnzymes, #OneReactant,#TwoReactants,#ThreeReactants,#OneProduct,#TwoProducts,
#ThreeProducts {
    background-color: rgb(245, 245, 245);
    border-radius:10px;
    display:none;
    border:1px solid lightgrey;
    width:91%;
}

#OneEnzyme:hover, #TwoEnzymes:hover, #ThreeEnzymes:hover, #OneReactant:hover,#TwoReactants:hover,#ThreeReactants:hover,#OneProduct:hover,
#TwoProducts:hover,#ThreeProducts:hover {
    background-color:rgb(23, 55, 151);
    color:white
}

#ReactionInputs {
    position: relative;   
}

#EntryInstructions {
    margin-left:150px;
    margin-top: 5px;
    color:white;
    padding:5px;
    background-color: rgb(245, 245, 245,0.25);
    width:100%;
    border-radius: 10px;
}

#EntryTitle {
    height:100vh;
    width:100vw;
    background-image:url("../../background.jpg");
    background-repeat:no-repeat;
    background-size:cover;
}

#ReactionInputs {
    margin-right: 150px;
    margin-left: 150px
}

#EnzymeName, #EnzymeECNumber, #EnzymeAASequence, #EnzymeSpecies, #EnzymeUniprot, #EnzymeGenbank, #ReactantName, #ReactantChebi, #ReactantSMILES,#CosubstrateName,#CosubstrateChebi,#CosubstrateSMILES, #ProductName, #ProductChebi, #ProductSMILES, #EnzymePublicationLink, #EnzymePMID, #EnzymePublicationDOI, #generalNote {
    background-color: rgb(245, 245, 245);
    margin-bottom:10px;
    border-radius:10px;
    padding:5px;
    width:100%;
}

#KingdomRadioInputs, #ClassRadioInputs, #TypeRadioInputs {
    background-color: rgb(245, 245, 245);
    margin-bottom:10px;
    margin-left:2px;
    border-radius:10px;
    border: solid black 1px;
    width:100%;
    padding:5px
}

#Enzyme, #Reactant, #Product, #Publication, #Cosubstrate {
    border-bottom: 2px solid lightgrey;
    padding:10px;
    margin-bottom: 10px;
}

#enzymeInputs, #reactantInputs, #productInputs, #cosubstrateInputs {
    margin-bottom: 20px;
}

#submitConfirmation, #addMechanism, #duplication, #rejectNote, #RejectNote {
    padding:20px;
    background-color: rgb(245, 245, 245);
    border:1px solid lightgrey;
    margin-bottom:25px;
    border-radius: 10px;
    justify-content: space-between;
    z-index: 5;
    width:50%;
    position:fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

}

#overlap {
    height: 100vh;
    width: 100vw;
    background-color: rgb(0,0,0,0.50);
    position:fixed;
    top:0;
    left:0;
    z-index: 3;
}

#submitConfirmation ul, #addMechanism ul, #duplication ul, #rejectNote ul,#RejectNote ul {
    list-style: none;
    display:flex;
    gap:0rem;
    align-items:center;
    justify-content: space-between;
}

#ok, #yes, #no {
    background-color:rgb(23, 55, 151);
    color:white;
    border-radius:10px;
    height:35px;
}



