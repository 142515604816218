#suggestpubbutton, #suggestdetailbutton {
    background-color: rgb(245,245,245);
    border:solid 2px rgb(211,211,211);
    padding: 10px;
    border-radius: 10px;
}

#suggestpubbutton:hover, #suggestdetailbutton:hover {
    background-color: gold;
}

#EntryTitleSuggestion {
    height:100vh;
    width:100vw;
    background-image:url("../../background.jpg");
    background-repeat:no-repeat;
    background-size:cover;
}

#fullForm {
    padding-bottom:25px;
    padding-top:125px
}

#confirmButtonPub, #confirmButtonFull {
    color:white;
    background-color: rgb(23, 55, 151);
    padding:5px;
    border-radius: 10px;
}

#SuggestionInstructions {
    margin-left:150px;
    margin-top: 5px;
    color:white;
    padding:5px;
    background-color: rgb(245, 245, 245,0.25);
    width:60%;
    border-radius: 10px;
}

#MechanismInput, #rejectNote {
    width:100%;
    padding:20px;
    background-color: rgb(245, 245, 245);
    border:1px solid lightgrey;
    margin-bottom:25px;
    border-radius: 10px;
    display:flex;
    justify-content: space-between;
    z-index: 5;
    position:fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

#mechanisminput {
    border-radius: 5px;
    border: 1px solid lightgrey;
    margin-right: 5px;
    width:150%
}

