#suggestionTable {
    width:100%;
}

#headerRowSuggestion {
    margin-top:20px;
    background-color:rgb(235, 235, 235);
    border:1px solid lightgrey;
    border-bottom:3px solid gold;
    text-align: center;
    box-shadow:0 6px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2)
}

#headerColSuggestion0 {
    padding-top:10px;
    padding-bottom:10px;
    border:1px solid lightgrey;
    width:25%
}
#headerColSuggestion1 {
    padding-top:10px;
    padding-bottom:10px;
    border:1px solid lightgrey;
    width:25%
}
#headerColSuggestion2 {
    padding-top:10px;
    padding-bottom:10px;
    border:1px solid lightgrey;
    width:25%
}
#headerColSuggestion3 {
    padding-top:10px;
    padding-bottom:10px;
    border:1px solid lightgrey;
    width:25%
} 


@media (max-width:900px) {
    #suggestionTable {
        width:100%;
        font-size: 8px;
    }

    #headerRowSuggestion {
        margin-top:10px;
        background-color:rgb(235, 235, 235);
        border:1px solid lightgrey;
        border-bottom:3px solid gold;
        text-align: center;
        box-shadow:0 6px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2)
    }

}