#table {
    width:100%;
}

#headerRow {
    margin-top:20px;
    background-color:rgb(235, 235, 235);
    border:1px solid lightgrey;
    border-bottom:3px solid gold;
    text-align: center;
    box-shadow:0 6px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2)
}

#tableBodyDiv {
    width:100%;
    border:1px solid black
}

 #headerCol0 {
    padding-top:10px;
    padding-bottom:10px;
    border:1px solid lightgrey;
    width:35%
}
#headerCol1 {
    padding-top:10px;
    padding-bottom:10px;
    border:1px solid lightgrey;
    width:25%
}
#headerCol2 {
    padding-top:10px;
    padding-bottom:10px;
    border:1px solid lightgrey;
    width:25%
}
#headerCol3 {
    padding-top:10px;
    padding-bottom:10px;
    border:1px solid lightgrey;
    width:15%
} 

#dataCol {
    background-color: rgb(245, 245, 245);
    border:1px solid lightgrey;
    text-align: center;
    word-wrap: break-word;
    width: min-content;
}

.DDPub {
    background-color: rgb(245, 245, 245);
    margin-top: 10px;
    border:1px solid lightgrey;
    padding:15px;
    position: absolute;
    width:100%;
    margin-right:150px;
    overflow-x: scroll;
}

.publicationList {
    color:black;
    text-decoration: none;
    min-width:130ch;
}

#detailsUl {
    margin:0;
    padding:0px;
    list-style: none;
    min-width: inherit;
}


#PublicationsUl, #ReactionUl, #EnzymeUl {
    border-bottom: 1px solid lightgrey;
    margin-top: 10px;

}

.iconbuttonseq, .iconbuttonreact,.iconbuttonprod  {
    border:none;
    background-color: rgb(245,245,245);
}

.copynotesequence {
    position:absolute;
    top:-2.5rem;
    left:7rem;
    background-color: rgb(23, 55, 151);
    color: white;
    padding:8px;
    font-size: small;
    opacity: 0;
    transition: all 250ms ease-in-out;
}

.iconbuttonseq:hover ~ .copynotesequence {
    opacity: 1;
}

.copynotesequence::before {
    content:"";
    position:absolute;
    bottom:-0.3rem;
    right:3.5rem;
    width:10px;
    height:10px;
    background-color: rgb(23, 55, 151);
    transform:rotate(45deg);
    border-right:3px solid rgb(23, 55, 151);
    border-bottom:3px solid rgb(23, 55, 151);
}

.copiednotesequence {
    position:absolute;
    top:-2.5rem;
    left:8.45rem;
    background-color: rgb(23, 55, 151);
    color: white;
    padding:8px;
    font-size: small;
    display:none;
    opacity: 1;
    transition: all 250ms ease-in-out;
}

.iconbuttonseq:hover ~ .copiednotesequence {
    opacity: 1;
}

.copiednotesequence::before {
    content:"";
    position:absolute;
    bottom:-0.3rem;
    right:2rem;
    width:10px;
    height:10px;
    background-color: rgb(23, 55, 151);
    transform:rotate(45deg);
    border-right:3px solid rgb(23, 55, 151);
    border-bottom:3px solid rgb(23, 55, 151);
}

.copynotereact {
    position:absolute;
    top:-2.5rem;
    left:5.5rem;
    background-color: rgb(23, 55, 151);
    color: white;
    padding:8px;
    font-size: small;
    opacity: 0;
    transition: all 250ms ease-in-out;
}

.iconbuttonreact:hover ~ .copynotereact {
    opacity: 1;
}

.copynotereact::before {
    content:"";
    position:absolute;
    bottom:-0.3rem;
    right:3.5rem;
    width:10px;
    height:10px;
    background-color: rgb(23, 55, 151);
    transform:rotate(45deg);
    border-right:3px solid rgb(23, 55, 151);
    border-bottom:3px solid rgb(23, 55, 151);
}

.copiednotereact {
    position:absolute;
    top:-2.5rem;
    left:6.95rem;
    background-color: rgb(23, 55, 151);
    color: white;
    padding:8px;
    font-size: small;
    display:none;
    opacity: 1;
    transition: all 250ms ease-in-out;
}

.iconbuttonreact:hover ~ .copiednotereact {
    opacity: 1;
}

.copiednotereact::before {
    content:"";
    position:absolute;
    bottom:-0.3rem;
    right:2rem;
    width:10px;
    height:10px;
    background-color: rgb(23, 55, 151);
    transform:rotate(45deg);
    border-right:3px solid rgb(23, 55, 151);
    border-bottom:3px solid rgb(23, 55, 151);
}

.copynoteprod {
    position:absolute;
    top:-2.5rem;
    left:4.8rem;
    background-color: rgb(23, 55, 151);
    color: white;
    padding:8px;
    font-size: small;
    opacity:0;
    transition: all 250ms ease-in-out
}

.iconbuttonprod:hover ~ .copynoteprod {
    opacity: 1;
}

.copynoteprod::before {
    content:"";
    position:absolute;
    bottom:-0.3rem;
    right:3.5rem;
    width:10px;
    height:10px;
    background-color: rgb(23, 55, 151);
    transform:rotate(45deg);
    border-right:3px solid rgb(23, 55, 151);
    border-bottom:3px solid rgb(23, 55, 151);
}

.copiednoteprod {
    position:absolute;
    top:-2.5rem;
    left:6.25rem;
    background-color: rgb(23, 55, 151);
    color: white;
    padding:8px;
    font-size: small;
    display:none;
    opacity: 1;
    transition: all 250ms ease-in-out;
}

.iconbuttonprod:hover ~ .copiednoteprod {
    opacity: 1;
}

.copiednoteprod::before {
    content:"";
    position:absolute;
    bottom:-0.3rem;
    right:2rem;
    width:10px;
    height:10px;
    background-color: rgb(23, 55, 151);
    transform:rotate(45deg);
    border-right:3px solid rgb(23, 55, 151);
    border-bottom:3px solid rgb(23, 55, 151);
}

.suggestDivComm {
    display: none;
    position: absolute;
    padding: 5px;
    background-color: rgb(23, 55, 151);
    top: 1.5rem;
    font-size: 12px;
    z-index: 3;
}

#commentButtonEnzyme, #commentButtonSubstrate, #commentButtonProduct {
    position: absolute;
    font-size: 12px;
    top: 0px;
    left: 0px;
    border-bottom: 1px lightgrey solid;
    border-right: 1px lightgrey solid;
    border-top: none;
    border-left: none;
    background-color: rgb(241,241,241);

}







@media (max-width:900px) {
    #table {
        width:100%;
        font-size: 10px;
    }

    #headerRow {
        margin-top:10px;
        background-color:rgb(235, 235, 235);
        border:1px solid lightgrey;
        border-bottom:3px solid gold;
        text-align: center;
        box-shadow:0 6px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2)
    }

    #commentButtonEnzyme, #commentButtonSubstrate, #commentButtonProduct {
        position: absolute;
        font-size: 10px;
        top: 0px;
        left: 0px;
        border-bottom: 1px lightgrey solid;
        border-right: 1px lightgrey solid;
        border-top: none;
        border-left: none;
        background-color: rgb(241,241,241);
        z-index: 2;
    }

    .suggestDivComm {
        display: none;
        position: absolute;
        padding: 5px;
        background-color: rgb(23, 55, 151);
        top: 1.5rem;
        font-size: 10px;
        z-index: 3;
    }

    .suggestInputComm, .confirmButtonComm {
        font-size:10px;
    }


}
